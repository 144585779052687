import {useEffect, useState} from 'react';
import {Outlet, useLocation, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {INavRoute} from './components-page/NavigationMain';
import AccountHeader from './components-page/AccountHeader';
import VideoBackground from './components-page/VideoBackground';
import Footer from './components-page/Footer';
import CookieConsent from './components-page/CookieConsent';
import {modalEffects} from './components-elements/Modal';
import WarningBanner from './components-elements/WarningBanner';

const GlobalLayout = (props: {routes: INavRoute[]}) => {
  const {t} = useTranslation();
  // -- on navigation, re-allow scrolling on document body --
  // modals disable body scrolling, so when a modal is open and the location changes (eg. back button),
  // the new page will not have scrolling (still disabled from unclosed modal)
  const l = useLocation();

  const [q] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    if (q.get('success') === 'true') {
      setTimeout(() => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 5000);
      }, 500);
    } else setIsSuccess(false);
    modalEffects(false);
  }, [l]);

  return (
    <div id="layout">
      <AccountHeader routes={props.routes} />
      <VideoBackground />

      <div id="content">
        <Outlet />
      </div>

      <Footer />
      <CookieConsent />
      {isSuccess &&
        <div id="success" style={{position: 'fixed', top: '14em', width: '100%', zIndex: 999}}>
          <WarningBanner type="info">{t('success')}</WarningBanner>
        </div>
      }
    </div>
);
};

export default GlobalLayout;
