import './RadioGroup.css';

interface IRadioGroupProps {
  /** Name for the group, must be unique - also functions as HTML id */
  groupId: string,
  title: string,
  options: {
    id: string,
    label: string
  }[],
  value: string,
  onChange: (v: string) => void
}

const RadioGroup = (props: IRadioGroupProps) => (
  <div id={props.groupId} className="radio-group">
    <span className="title">{props.title}</span>
    {props.options.map(x => (
      <div key={x.id}>
        <input type="radio" id={`${props.groupId}-${x.id}`} value={x.id}
          name={props.groupId} checked={props.value === x.id}
          onChange={e => props.onChange(e.currentTarget.value)} />
        <label htmlFor={x.id}>{x.label}</label>
      </div>
      ))}
  </div>
  );

export default RadioGroup;
