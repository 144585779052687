import {IoInformation, IoWarning} from 'react-icons/io5';
import './WarningBanner.css';
import {ReactElement} from 'react';

interface IWarningBannerProps {
  children: string | ReactElement,
  type: 'info' | 'warning' | 'error',
  show?: boolean
}
const WarningBanner = (props: IWarningBannerProps) => (
  <div className={`warning-banner ${props.type}`} style={{display: props.show === false ? 'none' : undefined}}>
    <div className="warning-icon">
      {props.type !== 'info' && <IoWarning />}
      {props.type === 'info' && <IoInformation />}
    </div>
    <div className="warning-text-wrapper">
      {props.children}
    </div>
  </div>
);

export default WarningBanner;
